import React from "react";
import Layout from "./Layout";
import Head from "./Head";
import Monogram from "./Monogram";
import Header from "./Header";
import { RaceResultItem } from "../graphql";
import RaceDistance from "./RaceResultDistance";
import MarkdownContent from "./MarkdownContent";
import { humanReadableDate, yearFromDate } from "../util/dates";
import { Link, PageProps } from "gatsby";
import Figure from "./Figure";
import * as classNames from "./RaceResultPage.module.css";
import ContentImage from "./ContentImage";

export type RaceResultPageProps = PageProps<null, RaceResultItem>;

/**
 * Functional component representing the results page
 */
const RaceResultPage: React.FC<RaceResultPageProps> = ({ pageContext }) => {
  const {
    body,
    frontmatter: { title, date, image, distances, place, time, url },
    excerpt,
  } = pageContext.childMdx;
  let pageTitle = `${title}`;
  let subTitle;
  if (date) {
    pageTitle += ` (${yearFromDate(date)})`;
    subTitle = `Raced ${humanReadableDate(date)}`;
  }
  return (
    <Layout>
      <Head title={pageTitle} image={image} description={excerpt} />
      <Header title={pageTitle} subtitle={subTitle} />
      <p className={classNames.distance}>
        <RaceDistance distances={distances} place={place} time={time} />
        {url && (
          <>
            {" "}
            -{" "}
            <a className={classNames.resultsLink} href={url}>
              Official Results
            </a>
          </>
        )}
      </p>
      <MarkdownContent html={body} dropcap={true} />
      {image && (
        <Figure>
          <ContentImage src={image} alt="" />
        </Figure>
      )}
      <Monogram footer={true} />
      <p>
        <Link to="/results/" className={classNames.backLink}>
          &laquo; All Race Results
        </Link>
      </p>
    </Layout>
  );
};

export default RaceResultPage;
