import React, { ReactNode } from "react";
import cx from "classnames";
import * as classNames from "./Icon.module.css";
import useHtmlId from "../util/use-html-id";

export enum IconType {
  Bike = "bike",
  Run = "run",
  Swim = "swim",
  Medal = "medal",
  Segments = "segments",
}

export interface IconProps {
  type: IconType;
  alt: string;
  className?: string;
}

function getScale(type: IconType): number {
  switch (type) {
    case IconType.Segments:
    case IconType.Medal:
      return 700;
    default:
      return 100;
  }
}

function getIcon(type: IconType): ReactNode {
  switch (type) {
    case IconType.Swim:
      return (
        <g className={classNames.swim}>
          <path d="M33.5,68.6c0.4,0.3,0.9,0.3,1.3,0c0.4-0.3,0.9-0.7,1.2-0.9c2.5-1.8,6.7-5,14-5c7.4,0,11.5,3.1,14,5     c0.3,0.2,0.8,0.5,1.2,0.9c0.4,0.3,0.9,0.3,1.3,0c0.4-0.3,0.9-0.7,1.1-0.8c0.8-0.6,1.8-1.3,3.1-2.1L59.9,47.2l1.1-14.8l14.6-3.7     c3.1-0.8,5-4,4.3-7.1c-0.8-3.1-4-5-7.1-4.3L54.1,22c-2.4,0.6-4.2,2.7-4.4,5.3l-1.4,18.9L23.8,63.4c4.1,1,6.8,3,8.6,4.3     C32.6,67.9,33.1,68.3,33.5,68.6z" />
          <path d="M91.9,72.8c-2.1-1.6-5-3.7-10.3-3.7c-5.3,0-8.2,2.1-10.3,3.7c-1.8,1.3-2.9,2.2-5.6,2.2c-2.6,0-3.7-0.8-5.5-2.2     c-2.1-1.6-5-3.7-10.3-3.7c-5.3,0-8.2,2.1-10.3,3.7c-1.8,1.3-2.9,2.2-5.6,2.2c-2.7,0-3.7-0.8-5.6-2.2c-2.1-1.6-5-3.7-10.3-3.7     c-5.3,0-8.2,2.1-10.3,3.7c-1.8,1.3-2.9,2.2-5.6,2.2v7.9c5.3,0,8.2-2.1,10.3-3.7c1.8-1.3,2.9-2.2,5.6-2.2c2.7,0,3.7,0.8,5.6,2.2     c2.1,1.6,5,3.7,10.3,3.7c5.3,0,8.2-2.1,10.3-3.7c1.8-1.3,2.9-2.2,5.6-2.2c2.6,0,3.7,0.8,5.5,2.2c2.1,1.6,5,3.7,10.3,3.7     c5.3,0,8.2-2.1,10.3-3.7c1.8-1.3,2.9-2.2,5.6-2.2c2.6,0,3.7,0.8,5.6,2.2c2.1,1.6,5,3.7,10.3,3.7v-7.9     C94.9,74.9,93.8,74.1,91.9,72.8z" />
          <circle cx="79" cy="46.6" r="12.4" />
        </g>
      );
    case IconType.Bike:
      return (
        <g className={classNames.bike}>
          <circle cx="19.7" cy="74.6" r="17.2" />
          <circle cx="80.3" cy="74.6" r="17.2" />
          <ellipse
            transform="matrix(0.1099 -0.9939 0.9939 0.1099 37.5533 75.1875)"
            cx="60.8"
            cy="16.6"
            rx="8.3"
            ry="8.3"
          />
          <path d="M55.9,44.1c0.7,1.6,2.2,2.7,4,2.7h9.8c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3h-6.9L60,31.5c-0.8-2-2.5-3.7-4.1-4.8     c-1.5-1.1-2.8-2-2.8-2c-3.5-2.4-8.2-1.7-10.8,1.7l-7.9,10.5c0,0-3.3,4.2-3.3,4.2c-2.3,3-1.9,7.3,1,9.7l13.9,11.8l-1.6,16.5     c-0.2,2.4,1.2,4.8,3.5,5.5c3,0.8,5.9-1.2,6.2-4.1L57,59.2c0.2-1.2-0.3-2.5-1.1-3.3l-9.4-9.5l6.7-8.7L55.9,44.1z" />
        </g>
      );
    case IconType.Run:
      return (
        <g className={classNames.run}>
          <path d="M89.2,45.1l-14.8-3c0,0-1.1-13.4-1.3-15.2c-0.1-0.7-0.2-1.6-0.5-2.6c-2.9-0.9-5.2-3-6.4-5.7c-0.5-0.1-0.9-0.2-1.3-0.2   c-1.3,0-17.1,0.5-17.1,0.5c-1.5-0.2-2.9,0.8-3.4,2.2l-4,15.5c-0.5,1.7-0.4,2.7,1.6,3s2.5-0.5,2.9-1.9l4.1-13l7.8,0   c-1.3,3.8-4,11.4-6.1,14.4c-1.8,2.5-5.4,6-6.5,9L31.4,59.6c-0.6,0.5-1.4,0.5-2,0.1L15.6,48.9c-2.1-2-4-2.5-5.7-0.6   c-1.8,2-1.1,4.6,1,6.5l15.8,14.7c1,0.9,2.3,1.4,3.5,1.4c1.2,0,2.3-0.4,3.3-1.2l16.8-13.6l15.4,13.3c0.4,0.4,0.8,0.9,1,1.4l8.8,20   c0.9,1.8,2.7,4.2,5.3,3.1c2.6-1.1,2.5-2.9,1.5-6.1l-7.2-22.4c-0.3-0.6-0.7-1.1-1.1-1.5L60.6,51.5c3.1-3.9,6.5-8.7,7.9-10.6l0.9,4.6   c0.2,1.3,1.3,2.4,2.6,2.6c0,0,16.6,1.8,16.8,1.8c1.5,0,2.1-0.3,2.3-1.9C91.4,46.2,91,45.4,89.2,45.1z" />
          <circle cx="75.9" cy="14.3" r="8.6" />
        </g>
      );
    case IconType.Medal:
      return (
        <g className={classNames.medal}>
          <path d="m350 213.92c39.199 0 75.602 12.879 104.72 34.719l92.961-226.8c1.6797-3.9219-1.1211-7.8398-5.0391-7.8398h-126c-2.2383 0-4.4805 1.1211-5.0391 3.3594l-61.602 150.08-61.602-150.08c-1.1211-2.2383-2.8008-3.3594-5.0391-3.3594h-126c-3.9219 0-6.7188 3.9219-5.0391 7.8398l93.52 226.8c28.559-21.84 64.961-34.719 104.16-34.719z" />
          <path d="m350 295.12c-52.078 0-94.641 42.559-94.641 94.641 0 7.2812 1.1211 14.559 2.8008 21.281 1.1211 3.9219 2.2383 8.3984 3.9219 12.32 1.1211 2.2383 2.2383 5.0391 3.3594 7.2812 4.4805 9.5195 10.641 18.48 18.48 26.32 17.359 17.359 40.879 27.441 66.641 27.441s49.84-10.641 66.641-27.441c7.2812-7.2812 13.441-16.238 18.48-26.32 1.1211-2.2383 2.2383-4.4805 3.3594-7.2812 1.6797-3.9219 2.8008-7.8398 3.9219-12.32 1.6797-6.7188 2.8008-14 2.8008-21.281-1.125-52.082-43.688-94.641-95.766-94.641z" />
          <path d="m350 234.08c-86.238 0-156.24 70-156.24 156.24 0 85.684 70 155.68 156.24 155.68s156.24-70 156.24-156.24c0-86.242-70-155.68-156.24-155.68zm0 269.92c-62.719 0-114.24-51.52-114.24-114.24 0-62.723 51.52-114.24 114.24-114.24s114.24 51.52 114.24 114.24c0 62.723-51.52 114.24-114.24 114.24z" />
        </g>
      );
    case IconType.Segments:
      return (
        <g className={classNames.segments}>
          <path d="m420.56 385.28h-48.16c-18.48 0-33.039-15.121-33.039-33.039 0-17.922 15.121-33.039 33.039-33.039h109.2c0-0.55859-0.55859-1.1211-0.55859-1.1211-3.3594-9.5195-6.1602-18.48-9.5195-27.441h-99.121c-34.16 0-61.602 28-61.602 61.602 0 34.16 28 61.602 61.602 61.602h47.602c18.48 0 33.039 15.121 33.039 33.039 0 18.48-15.121 33.039-33.039 33.039h-191.52c-3.3594 8.3984-6.1602 17.359-9.5195 26.32-0.55859 0.55859-0.55859 1.6797-1.1211 2.2383h202.16c34.16 0 61.602-27.441 61.602-61.602 0.55859-33.598-27.441-61.598-61.043-61.598z" />
          <path d="m266.56 331.52c0-52.078-43.121-93.52-95.762-91.281-47.035 2.2422-85.676 41.441-86.797 88.48-0.55859 22.398 6.7188 43.121 20.16 59.359 19.039 24.078 34.16 51.52 44.801 80.078 3.9219 10.078 7.2812 19.602 10.078 28.559 5.6016 15.68 27.441 15.68 33.039 0 2.8008-8.9609 6.7188-18.48 10.078-28.559 10.641-28.559 24.641-56 44.238-79.52 12.324-15.117 20.164-35.277 20.164-57.117zm-91.277 43.68c-24.078 0-43.68-19.602-43.68-43.68s19.602-43.68 43.68-43.68 43.68 19.602 43.68 43.68c0 24.082-19.602 43.68-43.68 43.68z" />
          <path d="m520.24 50.961c-47.039 2.2383-85.68 41.438-86.797 88.48-0.55859 22.398 6.7188 43.121 20.16 59.359 19.039 24.078 34.16 51.52 44.801 80.078 3.9219 10.078 7.2812 19.602 10.078 28.559 5.6016 15.68 27.441 15.68 33.039 0 2.8008-8.9609 6.7188-18.48 10.078-28.559 10.641-28.559 24.641-56 44.238-79.52 12.879-15.68 20.719-35.84 20.719-58.238-0.55469-50.961-43.676-92.402-96.316-90.16zm4.4805 134.96c-24.078 0-43.68-19.602-43.68-43.68 0-24.078 19.602-43.68 43.68-43.68s43.68 19.602 43.68 43.68c0 24.078-19.598 43.68-43.68 43.68z" />
        </g>
      );
  }
}

const Icon: React.FC<IconProps> = ({ className, alt, type }) => {
  const id = useHtmlId();
  const scale = getScale(type).toString(10);
  return (
    <svg
      viewBox={`0 0 ${scale} ${scale}`}
      className={cx(classNames.root, className)}
      role="img"
      aria-labelledby={id}
    >
      <title id={id}>{alt}</title>
      {getIcon(type)}
    </svg>
  );
};
export default Icon;
