import React, { ReactNode } from "react";
import cx from "classnames";
import * as classNames from "./Header.module.css";

export interface HeaderProps {
  title: ReactNode;
  subtitle?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ title, subtitle = "" }) => {
  return subtitle ? (
    <header className={classNames.header}>
      <h1 className={classNames.title}>{title}</h1>
      <p className={classNames.subtitle}>{subtitle}</p>
    </header>
  ) : (
    <h1 className={cx(classNames.header, classNames.title)}>{title}</h1>
  );
};

export default Header;
