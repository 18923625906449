import React from "react";
import Icon, { IconType } from "./Icon";
import { RaceDistance } from "../graphql";
import cx from "classnames";
import * as classNames from "./RaceResultDistance.module.css";

export interface RaceResultDistanceProps {
  distances?: RaceDistance[];
  place?: string;
  time?: string;
}

const places = new Map<string, string>([
  ["1st", classNames.place1],
  ["2nd", classNames.place2],
  ["3rd", classNames.place3],
]);

export const RaceResultPlace: React.FC<{ place?: string }> = ({
  place = "",
}) => {
  const placeClassName = places.get(place);
  return placeClassName ? (
    <Icon
      className={cx(classNames.place, placeClassName)}
      type={IconType.Medal}
      alt={`${place} place`}
    />
  ) : null;
};

const RaceResultDistance: React.FC<RaceResultDistanceProps> = ({
  distances,
  place,
  time,
}) => {
  const hasDistances = distances?.length;
  const content: React.ReactNode[] = [];
  if (hasDistances) {
    content.push(
      distances?.map(({ type, distance }, i) => (
        <span key={i}>
          {i > 0 && " / "}
          <Icon type={type as IconType} alt={type} />
          &nbsp;{distance}
        </span>
      ))
    );
  }
  if (hasDistances && time) {
    content.push(" - ");
  }
  if (time) {
    content.push(time);
  }
  content.push(<RaceResultPlace place={place} />);
  return <span className={classNames.root}>{content}</span>;
};

export default RaceResultDistance;
